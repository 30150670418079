import type { Translation } from "./Translation";

export const translation: Translation = {
  BankAccountForm: {
    accountNumberEmptyError: "Por favor ingrese numero de cuenta",
    accountNumberInvalidError: "Por favor ingrese un numero de cuenta vàlido. Es una cadena numérica de 4-17 dígitos.",
    accountNumberLabel: "Número de cuenta Bancaria",
    bankAccountNameEmptyError: "Por favor ingrese el nombre de la cuenta bancaria",
    bankAccountNameLabel: "Nombre de la cuenta bancaria",
    bankAccountTypeEmptyError: "Por favor seleccione el tipo de cuenta bancaria",
    bankAccountTypeLabel: "Tipo de cuenta Bancaria",
    bankAccountTypeOptionChecking: "Cheques",
    bankAccountTypeOptionSavings: "Ahorros",
    confirmAccountNumberEmptyError: "Por favor ingrese el mismo número de cuenta",
    confirmAccountNumberInvalidError: "Por favor ingrese el mismo número de cuenta",
    confirmAccountNumberLabel: "Confirme numero de cuenta",
    routingNumberEmptyError: "Por favor ingrese el número de ruta bancaria",
    routingNumberInvalidError:
      "El numero de ruta bancaria debe de tener 9 dígitos. Si su primer numero de ruta comienza con '0', ingréselo primero.",
    routingNumberLabel: "Numero de ruta bancaria",
    submitButtonText: "Enviar",
    toastSuccessMessage: "Sus datos bancarios han sido enviados",
  },
  BankAlreadyLinkedScreen: {
    contentSubtitleNative: "Vuelva a cargar la aplicación",
    contentSubtitleWeb: "Por favor actualice la página",
    contentTitle: "Su cuenta bancaria ya está conectada",
    headerTitle: "Conectar el estada del banco",
  },
  ChildCard: {
    amountCardLabelAvailable: "Disponible",
    amountCardLabelPending: "Pendiente",
    amountCardLabelSpent: "Gastado",
    claimAmount: "Cantidad reclamada",
    claimStatusApproved: "Aprobado",
    claimStatusPending: "Pendiente",
    claimStatusPendingExternal: "Pendiente",
    claimStatusRejected: "Rechazado",
    claimsHistoryAmountHeader: "Cantidad reclamada",
    claimsHistoryClaimIDHeader: "ID reclamada",
    claimsHistoryCollapseText: "Ocultar historial",
    claimsHistoryDateHeader: "Fecha",
    claimsHistorySeeMoreText: "Ver más historia",
    claimsHistoryStatusHeader: "Estado",
    expires: "Caduca",
    newClaimButtonText: "Nuevo reclamo",
  },
  ConfirmationModal: {
    cancel: "No",
    confirm: "Sí",
    title: "¿Estas seguro?",
  },
  Dashboard: {
    connectBankButtonText: "Conectar cuenta bancaria",
    connectBankDescription:
      "Hasta que haya configurado su cuenta bancaria, no podrá enviar recibos para su reembolso. Solo podrá enviar facturas que paguen directamente a un proveedor de servicios.",
    connectBankTitle: "Conecta tu cuenta bancaria para recibir reembolsos",
    helpCenter: "Centro de ayuda",
    logout: "Cerrar sesión",
    reconnectBankDescription:
      "Vuelva a conectar su cuenta bancaria para que pueda enviar los recibos para su reembolso. Si no, solo podrá enviar facturas que paguen directamente a un proveedor de servicios.",
    reconnectBankTitle: "Su cuenta bancaria ha sido desconectada.",
    title: "KEEP Claims",
  },
  DatePicker: {
    placeholder: "Seleccione fecha",
  },
  DocumentPicker: {
    errorDuplicateFile: "Archivo ya subido, por favor seleccione otro",
    errorFileTooLarge: "Seleccione un archivo que tenga menos de 10mb",
    errorFormatUnsupported: "El tipo de archivo seleccionado %{filetype} no es compatible",
    uploadButtonText: "Suba un archivo",
  },
  FileUploadItem: {
    documentNumberFormatError: "Por favor ingrese solo caracteres alfanuméricos",
    documentNumberLabel: "Número de cuenta/factura/recibo (opcional)",
    documentNumberPlaceholder: "Ingrese número",
  },
  Footer: {
    companyName: "Merit International \u00a9 2024",
    help: "Ayuda",
    privacy: "Privacidad",
    terms: "Términos",
  },
  ImagePicker: {
    errorDuplicateFile: "Archivo ya subido, seleccione otro",
    errorFileTooLarge: "Seleccione un archivo que tenga menos de 10mb",
    errorFormatUnsupported: "El tipo de archivo seleccionado %{filetype} no es compatible",
    errorSomethingWrongImage: "Algo anda mal con la imagen seleccionada",
    uploadButtonText: "Suba un archivo",
  },
  LinkBankAccountScreen: {
    exitConfirmation: "Si sale antes de enviar, su progreso se perderá",
    title: "Enviar detalles de su cuenta bancaria",
  },
  LoginSuccess: {
    loadingText: "Iniciando sesión…",
  },
  NotEligibleScreen: {
    subtitle: "Si cree que se trata de un error, comuníquese con %{externalLinkElement}",
    title: "Parece que no tienes ningún estudiante en KEEP",
  },
  Select: {
    placeholder: "Escoge una opción…",
  },
  ServiceProvider: {
    title: "Proveedor de servicio",
  },
  ServiceProviderForm: {
    addressEmptyError: "Por favor ingrese la dirección",
    addressLabel: "Dirección",
    addressPlaceholder: "Dirección",
    contactNameLabel: "Nombre de contacto (opcional)",
    contactNamePlaceholder: "Nombre de contacto (opcional)",
    contactTitleLabel: "Título del contacto (opcional)",
    contactTitlePlaceholder: "Título del contacto (opcional)",
    emailAddressEmptyError: "Por favor ingrese la dirección de correo electrónico",
    emailAddressInvalidError: "Dirección de correo electrónico no válida",
    emailAddressLabel: "Dirección de correo electrónico",
    emailAddressPlaceholder: "Dirección de correo electrónico",
    nameEmptyError: "Por favor ingresa un nombre",
    nameLabel: "Nombre",
    namePlaceholder: "Nombre",
    phoneNumberEmptyError: "Por favor ingrese el número de teléfono",
    phoneNumberInvalidError: "El número de teléfono no es válido",
    phoneNumberLabel: "Número de teléfono",
    phoneNumberPlaceholder: "Número de teléfono",
    saveButtonText: "Guardar",
    websiteEmptyError: "Por favor ingrese al sitio web",
    websiteInvalidError: "Por favor ingresa un sitio web válido",
    websiteLabel: "Sitio web",
    websitePlaceholder: "Sitio web",
  },
  ServiceProviderInfo: {
    addressLabel: "Dirección",
    contactNameLabel: "Nombre de contacto",
    contactTitleLabel: "Titulo de Contacto",
    editButtonText: "Editar",
    emailAddressLabel: "Dirección de correo electrónico",
    nameLabel: "Nombre",
    phoneNumberLabel: "Número de teléfono",
    removeButtonText: "Remover",
    title: "Información del proveedor de servicios",
    websiteLabel: "Sitio web",
  },
  SplashScreen: {
    help: "¿Necesita ayuda para acceder a su cuenta Merit?",
    login: "Iniciar sesión con Merit",
    subtitle: "Inicie sesión para administrar su cuenta de KEEP",
    title: "Bienvenido a Kansas Education Enrichment Program (KEEP)",
  },
  SubmitNewClaim: {
    amountDetail: "%{amount} restante",
    amountEmptyError: "Por favor ingrese la cantidad de la reclamación",
    amountInvalidError: "Por favor ingrese una cantidad válida",
    amountLabel: "Canditad de la reclamación",
    amountNoFundsAvailableError: "No hay fondos disponibles",
    amountRangeError: "Ingrese una cantidad entre $0.01 y %{amount}",
    attestationCheckboxText:
      "Doy fe de que la información que he proporcionado en este reclamo es verdadera y precisa. Además, doy fe de que los servicios no fueron proporcionados por un familiar del adjudicatario, los servicios se consideran valiosos desde el punto de vista educativo según la legislación de KEEP y fueron prestados por una organización o acreditación para realizar estos servicios. Entiendo que si estoy presentando un reclamo para un proveedor de servicios que aún no está precalificado en el Mercado KEEP, lo que puede aumentar el tiempo de procesamiento del reembolso.",
    categoryEmptyError: "Por favor seleccione una categoría de servicio",
    categoryLabel: "Categoría de servicio",
    categoryOptionCurriculumSchoolSuppliesTechnology: "Materiales curriculares / Útiles escolares / Tecnología",
    categoryOptionDayCamps: "Campamentos",
    categoryOptionInstrumentLessons: "Clases de música / Compras de instrumentos",
    categoryOptionLanguageClasses: "Clases de lenguaje",
    categoryOptionTutoring: "Tutoría",
    dateOfServiceEmptyError: "Por favor seleccione una fecha de servicio",
    dateOfServiceExpiredError: "La fecha del servicio debe ser anterior a que este premio expire el %{expirationDate}",
    dateOfServiceLabel: "Fecha de servicio",
    descriptionEmptyError:
      "Describa las habilidades que su hijo/a aprendío de este servicio que mejoraron su educación.",
    descriptionLabel: "¿Qué beneficios educativos recibió su hijo/a?",
    descriptionPlaceholder:
      "Describa las habilidades que su hijo/a aprendío de este servicio que mejoraron su educación.",
    exitConfirmation: "Si sale antes de enviar, su progreso se perderá",
    formSubmitButtonText: "Enviar",
    headerTitle: "Presentar un nuevo reclamo",
    invoiceBelowRangeError: "Por favor seleccione al menos 1 imagen de factura/recibo",
    invoiceEmptyError: "Por favor seleccione la imagen de la factura/recibo",
    invoiceUploadFilePayToSelfLabel:
      "Suba un recibo pagado para recibir el reembolso de esta compra (5 máximo, tipos de archivo admitidos: %{fileTypes})",
    invoiceUploadFilePayToVendorLabel:
      "Suba una factura impaga para que se pague directamente al proveedor de servicios (5 máximo, tipos de archivo admitidos: %{fileTypes} )",
    invoiceUploadFileTooltip:
      "Los recibos escritos a mano solo pueden aceptarse cuando los validan los proveedores de servicios",
    invoiceUploadedFileLabel: "Archivo subido",
    invoiceUploadedFileNumberLabel: "Numero de cuenta/factura/recibo (Opcional)",
    invoiceUploadedFileNumberPlaceholder: "Ingresar número",
    payToEmptyError: "Por favor seleccione 'Entregar fondos a'",
    payToLabel: "Entregar fondos a",
    payToOptionSelf: "Reembólsame [padre/tutor] por los gastos que ya pagué",
    payToOptionVendor: "Proveedor de servicio",
    serviceProviderCannotBePaidDirectlyError:
      "Actualmente no puede enviar un reclamo para este proveedor de servicios porque aún no ha conectado su cuenta bancaria y este proveedor de servicios actualmente no acepta pagos directos de las subvenciones de KEEP.",
    serviceProviderDetail: "Este es el número de proveedor para: %{serviceProviderTextElement}",
    serviceProviderInactiveError:
      "Este proveedor de servicios ya no está en el Mercado. Haga clic en el enlace siguiente para ingresar la información del proveedor.",
    serviceProviderModalTitle: "Proveedor de servicio",
    serviceProviderNotInProgramLinkText: "¿El proveedor de servicios no está actualmente en el KEEP?",
    serviceProviderNumberEmptyError: "Por favor ingrese un número de proveedor de servicios",
    serviceProviderNumberInvalidError: "Por favor ingrese un número de proveedor de servicios válido",
    serviceProviderNumberLabel: "Número de proveedor de servicios",
    serviceProviderRemoveConfirmation: "Esta accióno no se puede deshacer",
    serviceProviderTooltip:
      "Para encontrar un número de proveedor de servicios, visite el Mercado de Educación KEEP de %{externalLinkElement} y busque el nombre del proveedor. El número del proveedor de servicios aparecerá en la lista del mercado del proveedor.",
    studentLabel: "Estudiante",
    toastSuccessMessage: "Su reclamo ha sido enviado",
    updateAmountForAcceptedClaim: "Actualice el monto de un reclamo 'Aceptado'",
  },
};
