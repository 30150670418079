// Copyright 2022 Merit International Inc. All Rights Reserved

import { Button } from "./Button";
import { Image, Pressable, StyleSheet, Text, View } from "react-native";
import { Some } from "../utils/Some";
import { UnreachableCaseError } from "../utils/UnreachableCaseError";
import { formatCurrency } from "../utils/FormatHelper";
import { setTestProps } from "../utils/propHelper";
import { useDeviceSize } from "../utils/useDeviceSize";
import { useNavigation } from "@react-navigation/native";
import { useTranslation } from "../hooks/useTranslation";
import React, { useState } from "react";
import cashBillIcon from "../../assets/icons/cash_bill_m.png";
import checkmarkIcon from "../../assets/icons/checkmark_with_circle.png";
import dayjs from "dayjs";
import loadingIcon from "../../assets/icons/loading_m.png";
import type { LoginAsParentResponse } from "../__generated__/api/LoginRoute";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { RouteParamsAll } from "../Routes";
import type { TranslationKey } from "../__generated__/TranslationKey";

type Children = LoginAsParentResponse["children"];
type Status = Children[number]["claims"][number]["status"];

enum AmountCardType {
  Available = "available",
  Pending = "pending",
  Spent = "spent",
}

const amountCardTranslationKeys: Record<AmountCardType, TranslationKey> = {
  [AmountCardType.Available]: "ChildCard.amountCardLabelAvailable",
  [AmountCardType.Pending]: "ChildCard.amountCardLabelPending",
  [AmountCardType.Spent]: "ChildCard.amountCardLabelSpent",
};

type AmountCardProps = {
  readonly amount: number;
  readonly childName: string;
  readonly type: AmountCardType;
};

const AmountCard = ({ amount, childName, type }: AmountCardProps) => {
  const i18n = useTranslation();
  const styles = StyleSheet.create({
    amountIcon: {
      height: 20,
      width: 20,
    },
    amountText: {
      fontSize: 28,
      fontWeight: "600",
    },
    bodyText: {
      fontSize: 14,
      paddingBottom: 8,
    },
    contentWrapper: {
      backgroundColor: "#FAFBFC",
      borderRadius: 5,
      marginHorizontal: 8,
      marginVertical: 8,
      paddingHorizontal: 16,
      paddingVertical: 10,
    },
  });

  const getIcon = (imgType: AmountCardType) => {
    switch (imgType) {
      case AmountCardType.Available:
        return <Image source={cashBillIcon} style={styles.amountIcon} />;
      case AmountCardType.Pending:
        return <Image source={loadingIcon} style={styles.amountIcon} />;
      case AmountCardType.Spent:
        return <Image source={checkmarkIcon} style={styles.amountIcon} />;
      default:
        throw new UnreachableCaseError(imgType);
    }
  };

  return (
    <View style={{ flex: 1 }}>
      <View style={styles.contentWrapper}>
        <Text {...setTestProps({ name: `${type}AmountLabel-ChildCard` })} style={styles.bodyText}>
          {i18n.t(amountCardTranslationKeys[type])}
        </Text>
        <View style={{ position: "absolute", right: 10 }}>{getIcon(type)}</View>
        <Text style={styles.amountText} {...setTestProps({ name: `${childName}-${type}Amount-ChildCard` })}>
          {formatCurrency(amount)}
        </Text>
      </View>
    </View>
  );
};

// eslint-disable-next-line react/no-multi-comp
export const ChildCard = ({ claims, dollars, expirationDate, firstName, id, lastName }: Children[number]) => {
  const { isDesktopOrLarger } = useDeviceSize();
  const NUMBER_OF_MINIMUM_CLAIMS_TO_DISPLAY = 2;
  const [showAllClaims, setShowAllClaims] = useState(false);
  const claimsToShow = showAllClaims ? claims : claims.slice(0, NUMBER_OF_MINIMUM_CLAIMS_TO_DISPLAY);
  const navigation = useNavigation<NativeStackNavigationProp<RouteParamsAll>>();
  const i18n = useTranslation();

  const styles = StyleSheet.create({
    boxWrapper: {
      marginHorizontal: 8,
      marginVertical: 8,
    },
    buttonWrapper: {
      alignItems: "center",
      flexDirection: "row",
      marginHorizontal: isDesktopOrLarger ? 8 : 0,
      marginVertical: 8,
      paddingVertical: 10,
    },
    childInfoContainer: {
      flex: 1,
      marginRight: 8,
    },
    columnHeading: {
      fontSize: 14,
      fontWeight: "600",
    },
    columnHeadingWrapper: {
      flex: 1,
      paddingLeft: 8,
      paddingTop: 10,
    },
    container: {
      borderColor: "#C1C7D0",
      borderRadius: 4,
      borderWidth: 1,
    },
    contentWrapper: {
      backgroundColor: "#EEEBE3",
      borderRadius: 5,
      marginHorizontal: 8,
      marginVertical: 8,
      paddingHorizontal: 16,
      paddingVertical: 10,
    },
    header: {
      alignItems: "center",
      backgroundColor: "#FAFBFC",
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      flexDirection: "row",
      justifyContent: "space-between",
      padding: 16,
    },
    headerText: {
      color: "#000000",
      flexShrink: 1,
      fontSize: 16,
    },
    tableHeadingWrapper: {
      flexDirection: isDesktopOrLarger ? "row" : "column",
      marginTop: 5,
    },
  });
  const claimStyles = StyleSheet.create({
    borderStyle: {
      borderBottomColor: "#C1C7D0",
      borderBottomWidth: 1,
    },
    columnBodyWrapper: {
      flex: 1,
      paddingLeft: isDesktopOrLarger ? 8 : 0,
      paddingVertical: 10,
    },
    columnText: {
      fontSize: isDesktopOrLarger ? 14 : 12,
      fontWeight: "400",
    },
    tableBodyWrapper: {
      flexDirection: "row",
      marginTop: 5,
    },
  });

  const buttonText =
    claimsToShow.length < claims.length
      ? i18n.t("ChildCard.claimsHistorySeeMoreText")
      : i18n.t("ChildCard.claimsHistoryCollapseText");

  const fullName = `${firstName} ${lastName}`;

  const getStatusTitle = (status: Status) => {
    switch (status) {
      case "Pending Internal Review":
        return i18n.t("ChildCard.claimStatusPending");
      case "Pending Vendor Approval":
        return i18n.t("ChildCard.claimStatusPendingExternal");
      case "Accepted":
        return i18n.t("ChildCard.claimStatusApproved");
      case "Rejected":
        return i18n.t("ChildCard.claimStatusRejected");
      default:
        throw new UnreachableCaseError(status);
    }
  };

  const claimItems = (
    <View>
      {claimsToShow.map(({ amount, createdAt, id: claimId, status }) => (
        <View key={claimId} style={claimStyles.tableBodyWrapper}>
          {isDesktopOrLarger ? (
            <>
              <View style={[claimStyles.columnBodyWrapper, claimStyles.borderStyle]}>
                <Text style={claimStyles.columnText} {...setTestProps({ name: `${claimId}-SubmittedDate-ChildCard` })}>
                  {dayjs(createdAt).format("MM/DD/YYYY")}
                </Text>
              </View>
              <View style={[claimStyles.columnBodyWrapper, claimStyles.borderStyle]}>
                <Text style={claimStyles.columnText}>{claimId}</Text>
              </View>
              <View style={[claimStyles.columnBodyWrapper, claimStyles.borderStyle]}>
                <Text style={claimStyles.columnText} {...setTestProps({ name: `${claimId}-ClaimAmount-ChildCard` })}>
                  {formatCurrency(amount)}
                </Text>
              </View>
              <View style={[claimStyles.columnBodyWrapper, claimStyles.borderStyle]}>
                <Text
                  style={[claimStyles.columnText, { textAlign: "left" }]}
                  {...setTestProps({ name: `${claimId}-Status-ChildCard` })}
                >
                  {getStatusTitle(status)}
                </Text>
              </View>
            </>
          ) : (
            <>
              <View style={[claimStyles.columnBodyWrapper, claimStyles.borderStyle]}>
                <Text style={[claimStyles.columnText, { fontSize: 14 }]}>ID: #{claimId}</Text>
                <View style={claimStyles.columnBodyWrapper}>
                  <Text
                    style={[claimStyles.columnText, { fontSize: 24 }]}
                    {...setTestProps({ name: `${claimId}-ClaimAmount-ChildCard` })}
                  >
                    {formatCurrency(amount)}
                  </Text>
                </View>
              </View>
              <View style={[claimStyles.columnBodyWrapper, claimStyles.borderStyle]}>
                <Text
                  style={[claimStyles.columnText, { fontSize: 14, textAlign: "right" }]}
                  {...setTestProps({ name: `${claimId}-SubmittedDate-ChildCard` })}
                >
                  {dayjs(createdAt).format("MM/DD/YYYY")}
                </Text>
                <View style={claimStyles.columnBodyWrapper}>
                  <Text
                    style={[claimStyles.columnText, { fontSize: 14, textAlign: "right" }]}
                    {...setTestProps({ name: `${claimId}-Status-ChildCard` })}
                  >
                    {getStatusTitle(status)}
                  </Text>
                </View>
              </View>
            </>
          )}
        </View>
      ))}
    </View>
  );

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <View style={styles.childInfoContainer}>
          <Text numberOfLines={1} style={styles.headerText} {...setTestProps({ name: `${fullName}-ChildCard` })}>
            {fullName}
          </Text>
          {Some(expirationDate) && (
            <Text
              numberOfLines={1}
              style={[styles.headerText, { fontSize: 12 }]}
              {...setTestProps({ name: "expirationDate-ChildCard" })}
            >
              {i18n.t("ChildCard.expires")} {dayjs(expirationDate).format("MM/DD/YYYY")}
            </Text>
          )}
        </View>
        <Button
          onPress={() => {
            navigation.navigate("SubmitNewClaim", { childId: `${id}` });
          }}
          size="small"
          testID={`${fullName}-NewClaimButton-ChildCard`}
          text={i18n.t("ChildCard.newClaimButtonText")}
        />
      </View>
      <View>
        <View style={styles.boxWrapper}>
          {isDesktopOrLarger ? (
            <View style={{ flexDirection: "row" }}>
              <AmountCard amount={dollars.available} childName={fullName} type={AmountCardType.Available} />
              <AmountCard amount={dollars.pending} childName={fullName} type={AmountCardType.Pending} />
              <AmountCard amount={dollars.spent} childName={fullName} type={AmountCardType.Spent} />
            </View>
          ) : (
            <AmountCard amount={dollars.available} childName={fullName} type={AmountCardType.Available} />
          )}
          {!isDesktopOrLarger && (
            <View style={{ flexDirection: "row" }}>
              <AmountCard amount={dollars.pending} childName={fullName} type={AmountCardType.Pending} />
              <AmountCard amount={dollars.spent} childName={fullName} type={AmountCardType.Spent} />
            </View>
          )}
        </View>
        <View style={{ paddingHorizontal: isDesktopOrLarger ? 8 : 16 }}>
          {isDesktopOrLarger && claims.length > 0 && (
            <View style={styles.tableHeadingWrapper}>
              <View style={styles.columnHeadingWrapper}>
                <Text style={styles.columnHeading}>{i18n.t("ChildCard.claimsHistoryDateHeader")}</Text>
              </View>
              <View style={styles.columnHeadingWrapper}>
                <Text style={styles.columnHeading}>{i18n.t("ChildCard.claimsHistoryClaimIDHeader")}</Text>
              </View>
              <View style={styles.columnHeadingWrapper}>
                <Text style={styles.columnHeading}>{i18n.t("ChildCard.claimsHistoryAmountHeader")}</Text>
              </View>
              <View style={styles.columnHeadingWrapper}>
                <Text style={styles.columnHeading}>{i18n.t("ChildCard.claimsHistoryStatusHeader")}</Text>
              </View>
            </View>
          )}
          {claimItems}
          {claims.length > NUMBER_OF_MINIMUM_CLAIMS_TO_DISPLAY && (
            <View style={styles.buttonWrapper}>
              <Pressable
                onPress={() => {
                  setShowAllClaims((previousValue) => !previousValue);
                }}
              >
                <Text {...setTestProps({ name: `${buttonText}-Link-ChildCard` })} style={{ color: "#006699" }}>
                  {buttonText}
                </Text>
              </Pressable>
            </View>
          )}
        </View>
      </View>
    </View>
  );
};
