import * as Linking from "expo-linking";
import { Platform } from "react-native";

/**
 * Function opens any external URL. Based of the platform, using supported method to open the url.
 * @param link Any link to open
 */

export const openURL = (link: string) => {
  if (Platform.OS === "web") {
    window.open(link);
  } else {
    Linking.openURL(link);
  }
};
